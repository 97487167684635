@import 'shared';

.custom-input {
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    width: 100%;
    background: color(secondary, grey-b);
    border-radius: 30px;
    height: 44px;

    font-style: normal;
    font-weight: normal;
    font-size: toRem(18);
    line-height: 125%;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    color: color(secondary, conf-dark);

    &::placeholder {
      color: #c4c4c4;
    }
  }
}

/* Basic styles */
.custom-checkbox {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 50px;
    height: 1.5em;
    line-height: 1.5;
    cursor: pointer;
    color: color(white);
  }

  label::before,
  label::after {
    position: absolute;
    top: calc(50% - 9px);
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
  }

  label::before {
    content: ' ';
    border: 1px solid color(white);
  }

  /* Checkbox */
  input[type='checkbox'] + label::after {
    @include icon('icons/check.svg');
    background-size: 60%;
    content: ' ';
  }

  /* Radio */
  input[type='radio'] + label::before {
    border-radius: 50%;
  }

  input[type='radio'] + label::after {
    content: ' ';
    top: calc(50% - 9px);
    left: 0;
    width: 18px;
    height: 18px;
    background: color(white);
    border: 1px solid color(white);
    border-radius: 50%;
  }

  /* :checked */
  input[type='checkbox']:checked + label::before,
  input[type='radio']:checked + label::before {
    border-color: color(white);
  }

  input[type='checkbox'] + label::after,
  input[type='radio'] + label::after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }

  input[type='checkbox']:checked + label::after,
  input[type='radio']:checked + label::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  /* Transition */
  label::before,
  label::after {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
  &.black {
    label {
      color: color(black);
    }

    label::before {
      border: 1px solid color(black);
    }

    input[type='radio'] + label::after {
      background: color(black);
      border: 1px solid color(black);
    }
    input[type='checkbox'] + label::after {
      @include icon('icons/check-black.svg');
      background-size: 60%;
    }

    input[type='checkbox']:checked + label::before,
    input[type='radio']:checked + label::before {
      border-color: color(black);
    }
  }
}

.custom-checkbox-c {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 0 0 0 50px;
    height: 1.5em;
    line-height: 1.5;
    cursor: pointer;
    color: color(secondary, conf-dark);
  }

  label::before,
  label::after {
    position: absolute;
    top: calc(50% - 9px);
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
  }

  label::before {
    content: ' ';
    border: 1px solid color(secondary, conf-dark);
  }

  /* Checkbox */
  input[type='checkbox'] + label::after {
    @include icon('icons/check-black.svg');
    background-size: 60%;
    content: ' ';
  }

  /* Radio */
  input[type='radio'] + label::before {
    border-radius: 50%;
  }

  input[type='radio'] + label::after {
    content: ' ';
    top: calc(50% - 9px);
    left: 0;
    width: 18px;
    height: 18px;
    background: color(secondary, conf-dark);
    border: 1px solid color(secondary, conf-dark);
    border-radius: 50%;
  }

  /* :checked */
  input[type='checkbox']:checked + label::before,
  input[type='radio']:checked + label::before {
    border-color: color(secondary, conf-dark);
  }

  input[type='checkbox'] + label::after,
  input[type='radio'] + label::after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }

  input[type='checkbox']:checked + label::after,
  input[type='radio']:checked + label::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  /* Transition */
  label::before,
  label::after {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
  &.black {
    label {
      color: color(black);
    }

    label::before {
      border: 1px solid color(black);
    }

    input[type='radio'] + label::after {
      background: color(black);
      border: 1px solid color(black);
    }
    input[type='checkbox'] + label::after {
      @include icon('icons/check-black.svg');
      background-size: 60%;
    }

    input[type='checkbox']:checked + label::before,
    input[type='radio']:checked + label::before {
      border-color: color(black);
    }
  }
}

/* Basic styles */
.custom-checkbox-b {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 2px 0 0 30px;
    height: 1.5em;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: toRem(12);
    line-height: 15px;
    letter-spacing: 0.03em;
    color: color(black);
  }

  label::before,
  label::after {
    position: absolute;
    top: calc(50% - 9px);
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
  }

  label::before {
    border-radius: 50%;
    content: ' ';
    border: 1px solid color(primary, red);
  }

  /* Checkbox */
  input[type='checkbox'] + label::after {
    @include icon('icons/check-red.svg');
    background-size: 60%;
    content: ' ';
  }

  /* Radio */
  input[type='radio'] + label::before {
    border-radius: 50%;
  }

  input[type='radio'] + label::after {
    content: ' ';
    top: calc(50% - 9px);
    left: 0;
    width: 18px;
    height: 18px;
    background: color(primary, red);
    border: 1px solid color(primary, red);
    border-radius: 50%;
  }

  /* :checked */
  input[type='checkbox']:checked + label::before,
  input[type='radio']:checked + label::before {
    border-color: color(primary, red);
  }

  input[type='checkbox'] + label::after,
  input[type='radio'] + label::after {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }

  input[type='checkbox']:checked + label::after,
  input[type='radio']:checked + label::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  /* Transition */
  label::before,
  label::after {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
  }
  &.black {
    label {
      color: color(black);
    }

    label::before {
      border: 1px solid color(black);
    }

    input[type='radio'] + label::after {
      background: color(black);
      border: 1px solid color(black);
    }
    input[type='checkbox'] + label::after {
      @include icon('icons/check-black.svg');
      background-size: 60%;
    }

    input[type='checkbox']:checked + label::before,
    input[type='radio']:checked + label::before {
      border-color: color(black);
    }
  }
}

/* toggle-like-checkbox */
.custom-checkbox-toggle {
  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 2px 0 0 60px;
    height: 1.5em;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: toRem(16);
    line-height: 150%;
    letter-spacing: 0.01em;
    color: color(secondary, conf-dark);
  }

  label::before,
  label::after {
    position: absolute;
    display: block;
  }

  label::before {
    left: 0;
    top: calc(50% - 11px);
    background: color(secondary, grey-b);
    width: 48px;
    height: 24px;
    border-radius: 12px;
    content: ' ';
  }

  label::after {
    top: calc(50% - 9px);
    left: 2px;
    background: color(secondary, grey-e);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    content: ' ';
    transition: 0.3s ease all;
  }

  /* :checked */
  input[type='checkbox']:checked + label::after,
  input[type='radio']:checked + label::after {
    left: 26px;
    background: color(primary, green);
  }
}
