@import 'shared';

ul li .content {
  transition: transform 0.5s linear;
}

ul li:not(.slick-current) .content {
  transform: scale(0.9);
}

ul li .content {
  transition: transform 0.3s linear;
  transition-delay: 0.5s;
}

ul li:not(.slick-current) .content {
  transform: scale(0.9);
  transition-delay: 0s;
}

.slick-slide {
  outline: none;
}

// Slick component
.slick-prev,
.slick-next {
  width: 42px;
  height: 42px;
  z-index: 105;
  background-color: red;

  &:before {
    content: '';
  }

  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}

.slick-prev {
  left: 20px;
  //background: url('#{$images-path}/prev.webp') no-repeat center !important;
}

.slick-next {
  right: 20px;
  //background: url('#{$images-path}/next.webp') no-repeat center !important;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots {
  bottom: 25px;
  transform: translateX(-50%);
  width: auto;
  left: 50%;

  @include media-breakpoint-down(sm) {
    display: none !important;
    width: 100%;
  }

  li {
    width: auto;
    height: auto;
    margin: 0 7.5px;
    line-height: 0;

    &.slick-active {
      button {
        @include icon('icons/cardot-active.svg');
      }
    }

    @include media-breakpoint-down(sm) {
      margin: 0 2.5px;
    }

    button {
      @include icon('icons/cardot.svg');
      width: 28px;
      height: 28px;
      padding: 0;

      &:before {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
