@import 'shared';

.specicon {
  display: block;
  position: absolute;
  top: -55px;
  left: calc(50% - 70px);
  width: 110px;
  height: 110px;
  z-index: 7;
  opacity: 1;
}
