.custom-gradient:not(.sticky) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 51, 117, 0) 28.65%, rgba(15, 22, 45, 0.75) 100%);
  z-index: 1;
  pointer-events: none;
}
