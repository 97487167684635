@import 'shared';

.section-head {
  position: relative;
  padding-top: 80px;
  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }

  h2 {
    padding-top: 20px;
    display: block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    color: color(primary, blue);
    padding-bottom: 10px;
    max-width: calc(100% - 10px);
    margin: 0 auto;
  }

  .links {
    font-size: toRem(12);
    line-height: 150%;
    display: block;
    text-align: center;
    font-style: italic;
    padding-bottom: 8px;
    background: color(black);

    .link {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      color: color(primary, red);
      padding-left: 5px;
      padding-right: 5px;
      display: inline-block;
    }
  }

  .desc {
    display: block;
    text-align: center;
    font-style: italic;
    padding-bottom: 20px;
    width: 550px;
    max-width: calc(100% - 30px);
    margin: 0 auto;

    &:not(.keep-resp) {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
