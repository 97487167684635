// Global icons
*[class*='icon-'] {
  display: inline-block;
  &.icon-heart-full-red {
    @include icon('icons/heart-full-red.svg');
  }
  &.icon-heart-empty-red {
    @include icon('icons/heart-empty-red.svg');
  }
  &.icon-heart-empty-blue {
    @include icon('icons/heart-empty-blue.svg');
  }
  &.icon-heart-empty-white {
    @include icon('icons/icon-bucketlist.svg');
  }
  &.icon-arrow-down-more-red {
    @include icon('icons/arrow-down-more-red.webp');
  }
  &.icon-arrow-down-green {
    @include icon('icons/arrow-down-green.webp');
  }
  &.icon-arrow-down-white {
    @include icon('icons/arrow-down-more-white.webp');
  }
  &.icon-arrow-down-red {
    @include icon('icons/arrow-down-red.svg');
  }
  &.icon-arrow-up-red {
    @include icon('icons/arrow-up-red2.svg');
  }
  &.icon-arrow-right-more-red {
    @include icon('icons/arrow-right-more-red.webp');
  }
  &.icon-pin-red {
    @include icon('icons/pin-red.svg');
  }
  &.icon-pin-black {
    @include icon('icons/pin-black.svg');
  }
  &.icon-pin-green {
    @include icon('icons/icon-pin-24.svg');
  }
  &.icon-pin-blue-light {
    @include icon('icons/pin16-blue-light.svg');
  }
  &.icon-pin-black {
    @include icon('icons/icon-pin-black.svg');
  }
  &.icon-pin-purple {
    @include icon('icons/pin16-purple.svg');
  }
  &.icon-arrow-right-button-white {
    @include icon('icons/arrow-right-button-white.webp');
  }
  &.icon-profile-blue {
    @include icon('icons/profile-blue.svg');
  }
  &.icon-profile-white {
    @include icon('icons/profile-white.svg');
  }
  &.icon-search-blue {
    @include icon('icons/search-blue.svg');
  }
  &.icon-search-black {
    @include icon('icons/search-black.svg');
  }
  &.icon-logo {
    @include icon('main_Magyarorszag_horizontal_inverse.svg');
  }
  &.icon-logo-white {
    //@include icon('main_Magyarorszag_horizontal_white.svg');
    @include icon('magyarorszag_logo.svg');
  }
  &.icon-logo-notext {
    @include icon('main_Magyarorszag_sign.svg');
  }
  &.icon-search-white {
    @include icon('icons/icon-search.svg');
  }
  &.icon-search {
    @include icon('icons/icon-search.svg');
  }
  &.icon-search-green {
    @include icon('icons/icon-search-green.svg');
  }
  &.icon-search-red {
    @include icon('icons/icon-search-red.svg');
  }
  &.icon-burger-white {
    @include icon('icons/burger-white.svg');
  }
  &.icon-close-white {
    @include icon('icons/x.svg');
  }
  &.icon-close-red {
    @include icon('icons/x-red.svg');
  }
  &.icon-close-green {
    @include icon('icons/x-green.svg');
  }
  &.icon-close-purple {
    @include icon('icons/x-purple.svg');
  }
  &.icon-double-arrow {
    @include icon('icons/double-arrow.svg');
  }
  &.icon-arrow-left-white {
    @include icon('icons/arrow-left-white.webp');
  }
  &.icon-arrow-right-white {
    @include icon('icons/arrow-right-white.webp');
  }
  &.icon-arrow-right-green {
    @include icon('icons/arrow-right-green.webp');
  }
  &.icon-arrow-right-red {
    @include icon('icons/arrow-right-red.svg');
  }
  &.icon-photo {
    @include icon('icons/icon-photo.svg');
  }
  &.icon-list-view {
    @include icon('icons/icon-list-view.svg');
  }
  &.icon-list-view-white {
    @include icon('icons/icon-list-view-white.svg');
  }
  &.icon-filter {
    @include icon('icons/filter.svg');
  }
  &.icon-filter-current-color {
    @include icon('icons/filter-current-color.svg');
  }
  &.icon-map {
    @include icon('icons/map-icon.svg');
  }
  &.icon-impaired {
    @include icon('icons/impaired.webp');
  }
  &.icon-select-arrow-blue {
    @include icon('icons/select-arrow-blue.webp');
  }
  &.icon-calendar-purple {
    @include icon('icons/calendar-purple.webp');
  }
  &.icon-calendar-red {
    @include icon('icons/calendar-red.webp');
  }
  &.icon-calendar-green {
    @include icon('icons/calendar-green.webp');
  }
  &.icon-calendar-blue-light {
    @include icon('icons/calendar-blue-light.webp');
  }
  &.icon-calendar-white {
    @include icon('icons/icon-calendar-white.svg');
  }

  &.icon-flag-en {
    @include icon('icons/flags/en.svg');
  }
  &.icon-flag-hu {
    @include icon('icons/flags/hu.svg');
  }
  &.icon-flag-de {
    @include icon('icons/flags/de.svg');
  }
  &.icon-flag-es {
    @include icon('icons/flags/es.svg');
  }
  &.icon-flag-cn {
    @include icon('icons/flags/cn.svg');
  }
  &.icon-flag-hr {
    @include icon('icons/flags/hr.svg');
  }
  &.icon-flag-cs {
    @include icon('icons/flags/cs.svg');
  }
  &.icon-flag-fr {
    @include icon('icons/flags/fr.svg');
  }
  &.icon-flag-he {
    @include icon('icons/flags/he.svg');
  }
  &.icon-flag-it {
    @include icon('icons/flags/it.svg');
  }
  &.icon-flag-ko {
    @include icon('icons/flags/ko.svg');
  }
  &.icon-flag-pl {
    @include icon('icons/flags/pl.svg');
  }
  &.icon-flag-ro {
    @include icon('icons/flags/ro.svg');
  }
  &.icon-flag-ru {
    @include icon('icons/flags/ru.svg');
  }
  &.icon-flag-sr {
    @include icon('icons/flags/sr.svg');
  }
  &.icon-flag-sk {
    @include icon('icons/flags/sk.svg');
  }
  &.icon-flag-sl {
    @include icon('icons/flags/sl.svg');
  }
  &.icon-flag-sv {
    @include icon('icons/flags/se.svg');
  }

  &.icon-languages {
    @include icon('icons/icon-languages.svg');
  }
  &.icon-languages-red {
    @include icon('icons/icon-languages-red.svg');
  }
  &.icon-wifi {
    @include icon('icons/icon-wifi.svg');
  }
  &.icon-card {
    @include icon('icons/icon-card.svg');
  }
  &.icon-information {
    @include icon('icons/icon-information.svg');
  }

  &.icon-ccard {
    @include icon('icons/icon-ccard.webp');
  }
  &.icon-deaf {
    @include icon('icons/icon-deaf.webp');
  }
  &.icon-disabled {
    @include icon('icons/icon-disabled.webp');
  }
  &.icon-blind {
    @include icon('icons/icon-blind.webp');
  }

  &.icon-car {
    @include icon('icons/car-black.svg');
  }
  &.icon-by-car {
    @include icon('icons/icon-by-car.svg');
  }
  &.icon-by-car-gray {
    @include icon('icons/icon-by-car-gray.svg');
  }
  &.icon-train {
    @include icon('icons/train.svg');
  }
  &.icon-bicycle {
    @include icon('icons/icon-bicycle.svg');
  }
  &.icon-pedestrian {
    @include icon('icons/icon-pedestrian.svg');
  }
  &.icon-pedestrian-gray {
    @include icon('icons/icon-pedestrian-gray.svg');
  }

  &.icon-time {
    @include icon('icons/time.svg');
  }
  &.icon-pin {
    @include icon('icons/icon-pin-64.svg');
  }
  &.icon-pin-white {
    @include icon('icons/icon-pin-white.svg');
  }
  &.icon-pin-purple {
    @include icon('icons/icon-pin-purple.svg');
  }
  &.icon-weather {
    @include icon('icons/icon-weather.svg');
  }
  &.icon-download {
    @include icon('icons/download.svg');
  }

  &.icon-facebook-blue {
    @include icon('icons/soc-facebook-blue.svg');
  }
  &.icon-twitter-blue {
    @include icon('icons/soc-twitter-blue.svg');
  }
  &.icon-instagram-blue {
    @include icon('icons/soc-insta-blue.svg');
  }
  &.icon-facebook-white {
    @include icon('icons/soc-facebook-white.svg');
  }
  &.icon-twitter-white {
    @include icon('icons/soc-twitter-white.svg');
  }
  &.icon-instagram-white {
    @include icon('icons/soc-insta-white.svg');
  }
  &.icon-youtube-white {
    @include icon('icons/soc-youtube-white.svg');
  }
  &.icon-youtube-blue {
    @include icon('icons/soc-youtube-blue.svg');
  }

  &.icon-facebook-red {
    @include icon('icons/icon-facebook-red.svg');
  }
  &.icon-twitter-red {
    @include icon('icons/icon-twitter-red.svg');
  }
  &.icon-instagram-red {
    @include icon('icons/icon-insta-red.svg');
  }
  &.icon-mail-red {
    @include icon('icons/icon-mail-red.webp');
  }

  &.icon-spring {
    @include icon('icons/icon-spring.svg');
  }
  &.icon-summer {
    @include icon('icons/icon-summer.svg');
  }
  &.icon-autumn {
    @include icon('icons/icon-autumn.svg');
  }
  &.icon-winter {
    @include icon('icons/icon-winter.svg');
  }
  &.icon-sun {
    @include icon('icons/icon-sun.svg');
  }

  &.icon-spring-bold {
    @include icon('icons/icon-spring-bold.svg');
  }
  &.icon-summer-bold {
    @include icon('icons/icon-summer-bold.svg');
  }
  &.icon-autumn-bold {
    @include icon('icons/icon-autumn-bold.svg');
  }
  &.icon-winter-bold {
    @include icon('icons/icon-winter-bold.webp');
  }
  &.icon-sun-bold {
    @include icon('icons/icon-sun-bold.svg');
  }

  &.icon-plane {
    @include icon('icons/icon-how-to-get-there.svg');
  }
  &.icon-van {
    @include icon('icons/icon-getting-around.svg');
  }
  &.icon-tourinform {
    @include icon('icons/icon-tourinform.svg');
  }
  &.icon-tourinform-full {
    @include icon('icons/icon-tourinform-full.svg');
  }
  &.icon-tourinform-red {
    @include icon('icons/icon-tourinform-red.svg');
  }
  &.icon-smile {
    @include icon('icons/icon-accessible-hungary.svg');
  }
  &.icon-stats {
    @include icon('icons/icon-facts-figures.svg');
  }
  &.icon-dictionary {
    @include icon('icons/icon-dictionary.svg');
  }
  &.icon-currency {
    @include icon('icons/icon-currency.svg');
  }

  &.icon-mapeu-arrow {
    @include icon('mapicons/mapeu_arrow.webp');
  }
  &.icon-mapeu {
    @include icon('mapicons/eu.svg');
  }
  &.icon-map1 {
    @include icon('mapicons/map1.webp');
  }
  &.icon-map2 {
    @include icon('mapicons/map2.webp');
  }
  &.icon-map3 {
    @include icon('mapicons/map3.webp');
  }
  &.icon-map4 {
    @include icon('mapicons/map4.webp');
  }
  &.icon-map5 {
    @include icon('mapicons/map5.webp');
  }
  &.icon-map6 {
    @include icon('mapicons/map6.webp');
  }
  &.icon-map7 {
    @include icon('mapicons/map7.webp');
  }
  &.icon-map8 {
    @include icon('mapicons/map8.webp');
  }
  &.icon-map9 {
    @include icon('mapicons/map9.webp');
  }
  &.icon-map10 {
    @include icon('mapicons/map10.webp');
  }

  &.map-icon-0 {
    @include icon('mapicons/map-icon-0.svg');
  }
  &.map-icon-1 {
    @include icon('mapicons/map-icon-1.svg');
  }
  &.map-icon-2 {
    @include icon('mapicons/map-icon-2.svg');
  }
  &.map-icon-3 {
    @include icon('mapicons/map-icon-3.svg');
  }
  &.map-icon-4 {
    @include icon('mapicons/map-icon-4.svg');
  }
  &.map-icon-5 {
    @include icon('mapicons/map-icon-5.svg');
  }
  &.map-icon-6 {
    @include icon('mapicons/map-icon-6.svg');
  }
  &.map-icon-7 {
    @include icon('mapicons/map-icon-7.svg');
  }
  &.map-icon-8 {
    @include icon('mapicons/map-icon-8.svg');
  }
  &.map-icon-9 {
    @include icon('mapicons/map-icon-9.svg');
  }
  &.map-icon-10 {
    @include icon('mapicons/map-icon-10.svg');
  }

  &.thematic-icon-0 {
    @include icon('thematicicons/thematic-icon-0.svg');
  }
  &.thematic-icon-1 {
    @include icon('thematicicons/thematic-icon-1.svg');
  }
  &.thematic-icon-2 {
    @include icon('thematicicons/thematic-icon-2.svg');
  }
  &.thematic-icon-3 {
    @include icon('thematicicons/thematic-icon-3.svg');
  }
  &.thematic-icon-4 {
    @include icon('thematicicons/thematic-icon-4.svg');
  }
  &.thematic-icon-5 {
    @include icon('thematicicons/thematic-icon-5.svg');
  }
  &.thematic-icon-6 {
    @include icon('thematicicons/thematic-icon-6.svg');
  }
  &.thematic-icon-7 {
    @include icon('thematicicons/thematic-icon-7.svg');
  }
  &.thematic-icon-8 {
    @include icon('thematicicons/thematic-icon-8.svg');
  }
  &.thematic-icon-9 {
    @include icon('thematicicons/thematic-icon-9.svg');
  }
  &.thematic-icon-10 {
    @include icon('thematicicons/thematic-icon-10.svg');
  }
  &.thematic-icon-11 {
    @include icon('thematicicons/thematic-icon-11.svg');
  }
  &.thematic-icon-12 {
    @include icon('thematicicons/thematic-icon-12.svg');
  }
  &.thematic-icon-13 {
    @include icon('thematicicons/thematic-icon-13.svg');
  }
  &.thematic-icon-14 {
    @include icon('thematicicons/thematic-icon-14.svg');
  }
  &.thematic-icon-15 {
    @include icon('thematicicons/thematic-icon-15.svg');
  }
  &.thematic-icon-16 {
    @include icon('thematicicons/thematic-icon-16.svg');
  }
  &.thematic-icon-17 {
    @include icon('thematicicons/thematic-icon-17.svg');
  }
  &.thematic-icon-18 {
    @include icon('thematicicons/thematic-icon-18.svg');
  }
  &.thematic-icon-19 {
    @include icon('thematicicons/thematic-icon-19.svg');
  }
  &.thematic-icon-20 {
    @include icon('thematicicons/thematic-icon-20.svg');
  }
  &.thematic-icon-21 {
    @include icon('thematicicons/thematic-icon-21.svg');
  }
  &.thematic-icon-22 {
    @include icon('thematicicons/thematic-icon-22.svg');
  }
  &.thematic-icon-23 {
    @include icon('thematicicons/thematic-icon-23.svg');
  }

  &.functional-icon-0 {
    @include icon('functionalicons/functional-icon-0.svg');
  }
  &.functional-icon-1 {
    @include icon('functionalicons/functional-icon-1.svg');
  }
  &.functional-icon-2 {
    @include icon('functionalicons/functional-icon-2.svg');
  }
  &.functional-icon-3 {
    @include icon('functionalicons/functional-icon-3.svg');
  }
  &.functional-icon-4 {
    @include icon('functionalicons/functional-icon-4.svg');
  }
  &.functional-icon-5 {
    @include icon('functionalicons/functional-icon-5.svg');
  }
  &.functional-icon-6 {
    @include icon('functionalicons/functional-icon-6.svg');
  }
  &.functional-icon-7 {
    @include icon('functionalicons/functional-icon-7.svg');
  }
  &.functional-icon-8 {
    @include icon('functionalicons/functional-icon-8.svg');
  }
  &.functional-icon-9 {
    @include icon('functionalicons/functional-icon-9.svg');
  }
  &.functional-icon-10 {
    @include icon('functionalicons/functional-icon-10.svg');
  }
  &.functional-icon-11 {
    @include icon('functionalicons/functional-icon-11.svg');
  }
  &.functional-icon-12 {
    @include icon('functionalicons/functional-icon-12.svg');
  }
  &.functional-icon-13 {
    @include icon('functionalicons/functional-icon-13.svg');
  }
  &.functional-icon-14 {
    @include icon('functionalicons/functional-icon-14.svg');
  }
  &.functional-icon-15 {
    @include icon('functionalicons/functional-icon-15.svg');
  }
  &.functional-icon-16 {
    @include icon('functionalicons/functional-icon-16.svg');
  }
  &.functional-icon-18 {
    @include icon('functionalicons/functional-icon-18.svg');
  }
  &.functional-icon-19 {
    @include icon('functionalicons/functional-icon-19.svg');
  }

  &.red-functional-icon-0 {
    @include icon('functionalicons/functional-icon-red-0.svg');
  }
  &.red-functional-icon-1 {
    @include icon('functionalicons/functional-icon-red-1.svg');
  }
  &.red-functional-icon-2 {
    @include icon('functionalicons/functional-icon-red-2.svg');
  }
  &.red-functional-icon-3 {
    @include icon('functionalicons/functional-icon-red-3.svg');
  }
  &.red-functional-icon-4 {
    @include icon('functionalicons/functional-icon-red-4.svg');
  }
  &.red-functional-icon-5 {
    @include icon('functionalicons/functional-icon-red-5.svg');
  }
  &.red-functional-icon-6 {
    @include icon('functionalicons/functional-icon-red-6.svg');
  }
  &.red-functional-icon-7 {
    @include icon('functionalicons/functional-icon-red-7.svg');
  }
  &.red-functional-icon-8 {
    @include icon('functionalicons/functional-icon-red-8.svg');
  }
  &.red-functional-icon-9 {
    @include icon('functionalicons/functional-icon-red-9.svg');
  }
  &.red-functional-icon-10 {
    @include icon('functionalicons/functional-icon-red-10.svg');
  }
  &.red-functional-icon-11 {
    @include icon('functionalicons/functional-icon-red-11.svg');
  }
  &.red-functional-icon-12 {
    @include icon('functionalicons/functional-icon-red-12.svg');
  }
  &.red-functional-icon-13 {
    @include icon('functionalicons/functional-icon-red-13.svg');
  }
  &.red-functional-icon-14 {
    @include icon('functionalicons/functional-icon-red-14.svg');
  }
  &.red-functional-icon-15 {
    @include icon('functionalicons/functional-icon-red-15.svg');
  }
  &.red-functional-icon-16 {
    @include icon('functionalicons/functional-icon-red-16.svg');
  }
  &.red-functional-icon-18 {
    @include icon('functionalicons/functional-icon-red-18.svg');
  }
  &.red-functional-icon-19 {
    @include icon('functionalicons/functional-icon-red-19.svg');
  }

  &.red-functional-vehicle-icon-15 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-red-15.svg');
  }
  &.red-functional-vehicle-icon-16 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-red-16.svg');
  }
  &.red-functional-vehicle-icon-17 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-red-17.svg');
  }
  &.red-functional-vehicle-icon-18 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-red-18.svg');
  }
  &.red-functional-vehicle-icon-19 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-red-19.svg');
  }

  &.functional-vehicle-icon-15 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-15.svg');
  }
  &.functional-vehicle-icon-16 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-16.svg');
  }
  &.functional-vehicle-icon-17 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-17.svg');
  }
  &.functional-vehicle-icon-18 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-18.svg');
  }
  &.functional-vehicle-icon-19 {
    @include icon('functionalvehicleicons/functional-vehicle-icon-19.svg');
  }

  &.icon-budapest {
    @include icon('icons/budapest.svg');
  }
  &.icon-mtu {
    @include icon('icons/mtu.webp');
  }
  &.icon-store-google {
    @include icon('icons/store-google.svg');
  }
  &.icon-store-ios {
    @include icon('icons/store-ios.webp');
  }

  &.icon-login-facebook {
    @include icon('icons/login-facebook.svg');
  }
  &.icon-login-google {
    @include icon('icons/login-google.svg');
  }
  &.icon-login-username {
    @include icon('icons/login-username.svg');
  }
  &.icon-login-password {
    @include icon('icons/login-password.svg');
  }

  &.icon-city-notitle-balaton {
    @include icon('cities-notitle/balaton.svg');
  }
  &.icon-city-notitle-budapest {
    @include icon('cities-notitle/budapest.svg');
  }
  &.icon-city-notitle-debrecen {
    @include icon('cities-notitle/debrecen.svg');
  }
  &.icon-city-notitle-eger {
    @include icon('cities-notitle/eger.svg');
  }
  &.icon-city-notitle-gyor {
    @include icon('cities-notitle/gyor.svg');
  }
  &.icon-city-notitle-gyula {
    @include icon('cities-notitle/gyula.svg');
  }
  &.icon-city-notitle-pecs {
    @include icon('cities-notitle/pecs.svg');
  }
  &.icon-city-notitle-sopron {
    @include icon('cities-notitle/sopron.svg');
  }
  &.icon-city-notitle-szeged {
    @include icon('cities-notitle/szeged.svg');
  }
  &.icon-city-notitle-tokaj {
    @include icon('cities-notitle/tokaj.svg');
  }

  &.practical-info-icon-0 {
    @include icon('practical-icons/practical-info-icon-0.svg');
  }
  &.practical-info-icon-1 {
    @include icon('practical-icons/practical-info-icon-1.svg');
  }
  &.practical-info-icon-2 {
    @include icon('practical-icons/practical-info-icon-2.svg');
  }
  &.practical-info-icon-3 {
    @include icon('practical-icons/practical-info-icon-3.svg');
  }
  &.practical-info-icon-4 {
    @include icon('practical-icons/practical-info-icon-4.svg');
  }
  &.practical-info-icon-5 {
    @include icon('practical-icons/practical-info-icon-5.svg');
  }
  &.practical-info-icon-6 {
    @include icon('practical-icons/practical-info-icon-6.svg');
  }
  &.practical-info-icon-7 {
    @include icon('practical-icons/practical-info-icon-7.svg');
  }
  &.practical-info-icon-8 {
    @include icon('practical-icons/practical-info-icon-8.svg');
  }

  &.icon-mail-big {
    @include icon('icons/icon-mail-big.svg');
  }

  &.icon-accessibility {
    @include icon('icons/icon_accessibility.svg');
  }
  &.icon-accessibility-vision {
    @include icon('icons/icon_accessibility_vision.svg');
  }
  &.icon-accessibility-hearing {
    @include icon('icons/icon_accessibility_hearing.svg');
  }
}
