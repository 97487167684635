@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800&display=swap");
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 600px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 800px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 800px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
app-root {
  display: block;
  max-width: 100%;
  overflow: hidden;
}

section > .wrapper {
  width: 1180px;
  margin: 0 auto;
  max-width: calc(100% - 30px);
}

@media (min-width: 1024px) {
  .page-wrapper {
    margin-top: -68px;
  }
}
.section-head.padding-small {
  padding-top: 3px;
}

/* Focusing with a keyboard */
*:focus-visible,
*:focus-visible + label {
  outline: 8px solid #ffa200 !important;
}

/* Focusing with a mouse, touch, or stylus */
*:focus:not(:focus-visible) {
  outline: none !important;
}

input:focus:hover {
  outline: 0 !important;
}

.section-head {
  position: relative;
  padding-top: 80px;
}
@media (max-width: 1023.98px) {
  .section-head {
    padding-top: 20px;
  }
}
.section-head h2 {
  padding-top: 20px;
  display: block;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #003375;
  padding-bottom: 10px;
  max-width: calc(100% - 10px);
  margin: 0 auto;
}
.section-head .links {
  font-size: 0.75rem;
  line-height: 150%;
  display: block;
  text-align: center;
  font-style: italic;
  padding-bottom: 8px;
  background: #000;
}
.section-head .links .link {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  color: #ef4e69;
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}
.section-head .desc {
  display: block;
  text-align: center;
  font-style: italic;
  padding-bottom: 20px;
  width: 550px;
  max-width: calc(100% - 30px);
  margin: 0 auto;
}
@media (max-width: 1023.98px) {
  .section-head .desc:not(.keep-resp) {
    display: none;
  }
}

.custom-gradient:not(.sticky) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 51, 117, 0) 28.65%, rgba(15, 22, 45, 0.75) 100%);
  z-index: 1;
  pointer-events: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  *,
*:after,
*:before {
    transition: none !important;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-size: 1em;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

button {
  border: 0;
  background: transparent;
  border-radius: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

[hidden] {
  display: none !important;
}

select {
  word-wrap: normal;
}

button:focus,
a:focus,
input:focus,
textarea:focus {
  outline: none;
}

input,
textarea {
  border: 0;
  font-family: inherit;
}

img,
svg {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

img {
  border-style: none;
}

svg {
  overflow: hidden;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px "Montserrat", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px "Montserrat", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px "Montserrat", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px "Montserrat", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px "Montserrat", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px "Montserrat", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "Montserrat", sans-serif;
}

.mat-card {
  font-family: "Montserrat", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: "Montserrat", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "Montserrat", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: "Montserrat", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "Montserrat", sans-serif;
}

.mat-select {
  font-family: "Montserrat", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "Montserrat", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Montserrat", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "Montserrat", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px "Montserrat", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "Montserrat", sans-serif;
}

.mat-list-option {
  font-family: "Montserrat", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "Montserrat", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "Montserrat", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 125%;
}

h1 {
  font-size: 3.75rem;
}
@media (max-width: 1279.98px) {
  h1 {
    font-size: 2.8125rem;
  }
}

h2 {
  font-size: 3rem;
}
@media (max-width: 1279.98px) {
  h2 {
    font-size: 2.25rem;
  }
}

h3 {
  font-size: 1.875rem;
}
@media (max-width: 1279.98px) {
  h3 {
    font-size: 1.4375rem;
  }
}

h4 {
  font-size: 1.25rem;
}
@media (max-width: 1279.98px) {
  h4 {
    font-size: 0.9375rem;
  }
}

.highlighted-text {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 125%;
}
@media (max-width: 1279.98px) {
  .highlighted-text {
    font-size: 1.6875rem;
  }
}

.lead-text {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 150%;
}
@media (max-width: 1279.98px) {
  .lead-text {
    font-size: 1.4375rem;
  }
}

p {
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
}

.title-text-content ul,
.text-content ul {
  margin: 25px 0;
}
.title-text-content ul li,
.text-content ul li {
  padding-left: 25px;
  position: relative;
  margin-bottom: 15px;
}
.title-text-content ul li:before,
.text-content ul li:before {
  content: " ";
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  background: #ef4e69;
}

.lead-text.a,
.lead-text a,
.content-text.a,
.content-text a {
  color: #ef4e69;
  text-transform: uppercase;
  font-weight: bold;
}

*[class*=icon-] {
  display: inline-block;
}
*[class*=icon-].icon-heart-full-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/heart-full-red.svg");
}
*[class*=icon-].icon-heart-empty-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/heart-empty-red.svg");
}
*[class*=icon-].icon-heart-empty-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/heart-empty-blue.svg");
}
*[class*=icon-].icon-heart-empty-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-bucketlist.svg");
}
*[class*=icon-].icon-arrow-down-more-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-red.webp");
}
*[class*=icon-].icon-arrow-down-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-green.webp");
}
*[class*=icon-].icon-arrow-down-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-white.webp");
}
*[class*=icon-].icon-arrow-down-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-red.svg");
}
*[class*=icon-].icon-arrow-up-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-up-red2.svg");
}
*[class*=icon-].icon-arrow-right-more-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-more-red.webp");
}
*[class*=icon-].icon-pin-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin-red.svg");
}
*[class*=icon-].icon-pin-black {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin-black.svg");
}
*[class*=icon-].icon-pin-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-24.svg");
}
*[class*=icon-].icon-pin-blue-light {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin16-blue-light.svg");
}
*[class*=icon-].icon-pin-black {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-black.svg");
}
*[class*=icon-].icon-pin-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/pin16-purple.svg");
}
*[class*=icon-].icon-arrow-right-button-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-button-white.webp");
}
*[class*=icon-].icon-profile-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/profile-blue.svg");
}
*[class*=icon-].icon-profile-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/profile-white.svg");
}
*[class*=icon-].icon-search-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/search-blue.svg");
}
*[class*=icon-].icon-search-black {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/search-black.svg");
}
*[class*=icon-].icon-logo {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/main_Magyarorszag_horizontal_inverse.svg");
}
*[class*=icon-].icon-logo-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/magyarorszag_logo.svg");
}
*[class*=icon-].icon-logo-notext {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/main_Magyarorszag_sign.svg");
}
*[class*=icon-].icon-search-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search.svg");
}
*[class*=icon-].icon-search {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search.svg");
}
*[class*=icon-].icon-search-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search-green.svg");
}
*[class*=icon-].icon-search-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-search-red.svg");
}
*[class*=icon-].icon-burger-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/burger-white.svg");
}
*[class*=icon-].icon-close-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x.svg");
}
*[class*=icon-].icon-close-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x-red.svg");
}
*[class*=icon-].icon-close-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x-green.svg");
}
*[class*=icon-].icon-close-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x-purple.svg");
}
*[class*=icon-].icon-double-arrow {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/double-arrow.svg");
}
*[class*=icon-].icon-arrow-left-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-left-white.webp");
}
*[class*=icon-].icon-arrow-right-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-white.webp");
}
*[class*=icon-].icon-arrow-right-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-green.webp");
}
*[class*=icon-].icon-arrow-right-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-right-red.svg");
}
*[class*=icon-].icon-photo {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-photo.svg");
}
*[class*=icon-].icon-list-view {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-list-view.svg");
}
*[class*=icon-].icon-list-view-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-list-view-white.svg");
}
*[class*=icon-].icon-filter {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/filter.svg");
}
*[class*=icon-].icon-filter-current-color {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/filter-current-color.svg");
}
*[class*=icon-].icon-map {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/map-icon.svg");
}
*[class*=icon-].icon-impaired {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/impaired.webp");
}
*[class*=icon-].icon-select-arrow-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/select-arrow-blue.webp");
}
*[class*=icon-].icon-calendar-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-purple.webp");
}
*[class*=icon-].icon-calendar-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-red.webp");
}
*[class*=icon-].icon-calendar-green {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-green.webp");
}
*[class*=icon-].icon-calendar-blue-light {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/calendar-blue-light.webp");
}
*[class*=icon-].icon-calendar-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-calendar-white.svg");
}
*[class*=icon-].icon-flag-en {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/en.svg");
}
*[class*=icon-].icon-flag-hu {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/hu.svg");
}
*[class*=icon-].icon-flag-de {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/de.svg");
}
*[class*=icon-].icon-flag-es {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/es.svg");
}
*[class*=icon-].icon-flag-cn {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/cn.svg");
}
*[class*=icon-].icon-flag-hr {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/hr.svg");
}
*[class*=icon-].icon-flag-cs {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/cs.svg");
}
*[class*=icon-].icon-flag-fr {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/fr.svg");
}
*[class*=icon-].icon-flag-he {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/he.svg");
}
*[class*=icon-].icon-flag-it {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/it.svg");
}
*[class*=icon-].icon-flag-ko {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/ko.svg");
}
*[class*=icon-].icon-flag-pl {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/pl.svg");
}
*[class*=icon-].icon-flag-ro {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/ro.svg");
}
*[class*=icon-].icon-flag-ru {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/ru.svg");
}
*[class*=icon-].icon-flag-sr {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/sr.svg");
}
*[class*=icon-].icon-flag-sk {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/sk.svg");
}
*[class*=icon-].icon-flag-sl {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/sl.svg");
}
*[class*=icon-].icon-flag-sv {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/flags/se.svg");
}
*[class*=icon-].icon-languages {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-languages.svg");
}
*[class*=icon-].icon-languages-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-languages-red.svg");
}
*[class*=icon-].icon-wifi {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-wifi.svg");
}
*[class*=icon-].icon-card {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-card.svg");
}
*[class*=icon-].icon-information {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-information.svg");
}
*[class*=icon-].icon-ccard {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-ccard.webp");
}
*[class*=icon-].icon-deaf {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-deaf.webp");
}
*[class*=icon-].icon-disabled {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-disabled.webp");
}
*[class*=icon-].icon-blind {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-blind.webp");
}
*[class*=icon-].icon-car {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/car-black.svg");
}
*[class*=icon-].icon-by-car {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-by-car.svg");
}
*[class*=icon-].icon-by-car-gray {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-by-car-gray.svg");
}
*[class*=icon-].icon-train {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/train.svg");
}
*[class*=icon-].icon-bicycle {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-bicycle.svg");
}
*[class*=icon-].icon-pedestrian {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pedestrian.svg");
}
*[class*=icon-].icon-pedestrian-gray {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pedestrian-gray.svg");
}
*[class*=icon-].icon-time {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/time.svg");
}
*[class*=icon-].icon-pin {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-64.svg");
}
*[class*=icon-].icon-pin-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-white.svg");
}
*[class*=icon-].icon-pin-purple {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-pin-purple.svg");
}
*[class*=icon-].icon-weather {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-weather.svg");
}
*[class*=icon-].icon-download {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/download.svg");
}
*[class*=icon-].icon-facebook-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-facebook-blue.svg");
}
*[class*=icon-].icon-twitter-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-twitter-blue.svg");
}
*[class*=icon-].icon-instagram-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-insta-blue.svg");
}
*[class*=icon-].icon-facebook-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-facebook-white.svg");
}
*[class*=icon-].icon-twitter-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-twitter-white.svg");
}
*[class*=icon-].icon-instagram-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-insta-white.svg");
}
*[class*=icon-].icon-youtube-white {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-youtube-white.svg");
}
*[class*=icon-].icon-youtube-blue {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/soc-youtube-blue.svg");
}
*[class*=icon-].icon-facebook-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-facebook-red.svg");
}
*[class*=icon-].icon-twitter-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-twitter-red.svg");
}
*[class*=icon-].icon-instagram-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-insta-red.svg");
}
*[class*=icon-].icon-mail-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-mail-red.webp");
}
*[class*=icon-].icon-spring {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-spring.svg");
}
*[class*=icon-].icon-summer {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-summer.svg");
}
*[class*=icon-].icon-autumn {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-autumn.svg");
}
*[class*=icon-].icon-winter {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-winter.svg");
}
*[class*=icon-].icon-sun {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-sun.svg");
}
*[class*=icon-].icon-spring-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-spring-bold.svg");
}
*[class*=icon-].icon-summer-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-summer-bold.svg");
}
*[class*=icon-].icon-autumn-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-autumn-bold.svg");
}
*[class*=icon-].icon-winter-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-winter-bold.webp");
}
*[class*=icon-].icon-sun-bold {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-sun-bold.svg");
}
*[class*=icon-].icon-plane {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-how-to-get-there.svg");
}
*[class*=icon-].icon-van {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-getting-around.svg");
}
*[class*=icon-].icon-tourinform {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-tourinform.svg");
}
*[class*=icon-].icon-tourinform-full {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-tourinform-full.svg");
}
*[class*=icon-].icon-tourinform-red {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-tourinform-red.svg");
}
*[class*=icon-].icon-smile {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-accessible-hungary.svg");
}
*[class*=icon-].icon-stats {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-facts-figures.svg");
}
*[class*=icon-].icon-dictionary {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-dictionary.svg");
}
*[class*=icon-].icon-currency {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-currency.svg");
}
*[class*=icon-].icon-mapeu-arrow {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/mapeu_arrow.webp");
}
*[class*=icon-].icon-mapeu {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/eu.svg");
}
*[class*=icon-].icon-map1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map1.webp");
}
*[class*=icon-].icon-map2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map2.webp");
}
*[class*=icon-].icon-map3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map3.webp");
}
*[class*=icon-].icon-map4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map4.webp");
}
*[class*=icon-].icon-map5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map5.webp");
}
*[class*=icon-].icon-map6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map6.webp");
}
*[class*=icon-].icon-map7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map7.webp");
}
*[class*=icon-].icon-map8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map8.webp");
}
*[class*=icon-].icon-map9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map9.webp");
}
*[class*=icon-].icon-map10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map10.webp");
}
*[class*=icon-].map-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-0.svg");
}
*[class*=icon-].map-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-1.svg");
}
*[class*=icon-].map-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-2.svg");
}
*[class*=icon-].map-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-3.svg");
}
*[class*=icon-].map-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-4.svg");
}
*[class*=icon-].map-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-5.svg");
}
*[class*=icon-].map-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-6.svg");
}
*[class*=icon-].map-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-7.svg");
}
*[class*=icon-].map-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-8.svg");
}
*[class*=icon-].map-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-9.svg");
}
*[class*=icon-].map-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/mapicons/map-icon-10.svg");
}
*[class*=icon-].thematic-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-0.svg");
}
*[class*=icon-].thematic-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-1.svg");
}
*[class*=icon-].thematic-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-2.svg");
}
*[class*=icon-].thematic-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-3.svg");
}
*[class*=icon-].thematic-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-4.svg");
}
*[class*=icon-].thematic-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-5.svg");
}
*[class*=icon-].thematic-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-6.svg");
}
*[class*=icon-].thematic-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-7.svg");
}
*[class*=icon-].thematic-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-8.svg");
}
*[class*=icon-].thematic-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-9.svg");
}
*[class*=icon-].thematic-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-10.svg");
}
*[class*=icon-].thematic-icon-11 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-11.svg");
}
*[class*=icon-].thematic-icon-12 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-12.svg");
}
*[class*=icon-].thematic-icon-13 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-13.svg");
}
*[class*=icon-].thematic-icon-14 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-14.svg");
}
*[class*=icon-].thematic-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-15.svg");
}
*[class*=icon-].thematic-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-16.svg");
}
*[class*=icon-].thematic-icon-17 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-17.svg");
}
*[class*=icon-].thematic-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-18.svg");
}
*[class*=icon-].thematic-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-19.svg");
}
*[class*=icon-].thematic-icon-20 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-20.svg");
}
*[class*=icon-].thematic-icon-21 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-21.svg");
}
*[class*=icon-].thematic-icon-22 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-22.svg");
}
*[class*=icon-].thematic-icon-23 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/thematicicons/thematic-icon-23.svg");
}
*[class*=icon-].functional-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-0.svg");
}
*[class*=icon-].functional-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-1.svg");
}
*[class*=icon-].functional-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-2.svg");
}
*[class*=icon-].functional-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-3.svg");
}
*[class*=icon-].functional-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-4.svg");
}
*[class*=icon-].functional-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-5.svg");
}
*[class*=icon-].functional-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-6.svg");
}
*[class*=icon-].functional-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-7.svg");
}
*[class*=icon-].functional-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-8.svg");
}
*[class*=icon-].functional-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-9.svg");
}
*[class*=icon-].functional-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-10.svg");
}
*[class*=icon-].functional-icon-11 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-11.svg");
}
*[class*=icon-].functional-icon-12 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-12.svg");
}
*[class*=icon-].functional-icon-13 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-13.svg");
}
*[class*=icon-].functional-icon-14 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-14.svg");
}
*[class*=icon-].functional-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-15.svg");
}
*[class*=icon-].functional-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-16.svg");
}
*[class*=icon-].functional-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-18.svg");
}
*[class*=icon-].functional-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-19.svg");
}
*[class*=icon-].red-functional-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-0.svg");
}
*[class*=icon-].red-functional-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-1.svg");
}
*[class*=icon-].red-functional-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-2.svg");
}
*[class*=icon-].red-functional-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-3.svg");
}
*[class*=icon-].red-functional-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-4.svg");
}
*[class*=icon-].red-functional-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-5.svg");
}
*[class*=icon-].red-functional-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-6.svg");
}
*[class*=icon-].red-functional-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-7.svg");
}
*[class*=icon-].red-functional-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-8.svg");
}
*[class*=icon-].red-functional-icon-9 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-9.svg");
}
*[class*=icon-].red-functional-icon-10 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-10.svg");
}
*[class*=icon-].red-functional-icon-11 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-11.svg");
}
*[class*=icon-].red-functional-icon-12 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-12.svg");
}
*[class*=icon-].red-functional-icon-13 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-13.svg");
}
*[class*=icon-].red-functional-icon-14 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-14.svg");
}
*[class*=icon-].red-functional-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-15.svg");
}
*[class*=icon-].red-functional-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-16.svg");
}
*[class*=icon-].red-functional-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-18.svg");
}
*[class*=icon-].red-functional-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalicons/functional-icon-red-19.svg");
}
*[class*=icon-].red-functional-vehicle-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-15.svg");
}
*[class*=icon-].red-functional-vehicle-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-16.svg");
}
*[class*=icon-].red-functional-vehicle-icon-17 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-17.svg");
}
*[class*=icon-].red-functional-vehicle-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-18.svg");
}
*[class*=icon-].red-functional-vehicle-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-red-19.svg");
}
*[class*=icon-].functional-vehicle-icon-15 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-15.svg");
}
*[class*=icon-].functional-vehicle-icon-16 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-16.svg");
}
*[class*=icon-].functional-vehicle-icon-17 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-17.svg");
}
*[class*=icon-].functional-vehicle-icon-18 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-18.svg");
}
*[class*=icon-].functional-vehicle-icon-19 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/functionalvehicleicons/functional-vehicle-icon-19.svg");
}
*[class*=icon-].icon-budapest {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/budapest.svg");
}
*[class*=icon-].icon-mtu {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/mtu.webp");
}
*[class*=icon-].icon-store-google {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/store-google.svg");
}
*[class*=icon-].icon-store-ios {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/store-ios.webp");
}
*[class*=icon-].icon-login-facebook {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-facebook.svg");
}
*[class*=icon-].icon-login-google {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-google.svg");
}
*[class*=icon-].icon-login-username {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-username.svg");
}
*[class*=icon-].icon-login-password {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/login-password.svg");
}
*[class*=icon-].icon-city-notitle-balaton {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/balaton.svg");
}
*[class*=icon-].icon-city-notitle-budapest {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/budapest.svg");
}
*[class*=icon-].icon-city-notitle-debrecen {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/debrecen.svg");
}
*[class*=icon-].icon-city-notitle-eger {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/eger.svg");
}
*[class*=icon-].icon-city-notitle-gyor {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/gyor.svg");
}
*[class*=icon-].icon-city-notitle-gyula {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/gyula.svg");
}
*[class*=icon-].icon-city-notitle-pecs {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/pecs.svg");
}
*[class*=icon-].icon-city-notitle-sopron {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/sopron.svg");
}
*[class*=icon-].icon-city-notitle-szeged {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/szeged.svg");
}
*[class*=icon-].icon-city-notitle-tokaj {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/cities-notitle/tokaj.svg");
}
*[class*=icon-].practical-info-icon-0 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-0.svg");
}
*[class*=icon-].practical-info-icon-1 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-1.svg");
}
*[class*=icon-].practical-info-icon-2 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-2.svg");
}
*[class*=icon-].practical-info-icon-3 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-3.svg");
}
*[class*=icon-].practical-info-icon-4 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-4.svg");
}
*[class*=icon-].practical-info-icon-5 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-5.svg");
}
*[class*=icon-].practical-info-icon-6 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-6.svg");
}
*[class*=icon-].practical-info-icon-7 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-7.svg");
}
*[class*=icon-].practical-info-icon-8 {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/practical-icons/practical-info-icon-8.svg");
}
*[class*=icon-].icon-mail-big {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon-mail-big.svg");
}
*[class*=icon-].icon-accessibility {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon_accessibility.svg");
}
*[class*=icon-].icon-accessibility-vision {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon_accessibility_vision.svg");
}
*[class*=icon-].icon-accessibility-hearing {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/icon_accessibility_hearing.svg");
}

.button {
  transition: 0.3s ease all;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  /* ------------------- PRIMARY AND SECONDARY ------------------- */
  /* ------------------- TEXT BUTTON ------------------- */
  /* ------------------- SPEC BUTTON ------------------- */
}
.button:focus, .button:active {
  outline: none;
}
.button.button-primary, .button.button-secondary {
  font-weight: bold;
  font-size: 1rem;
  line-height: 112.5%;
  border: 2px solid #ef4e69;
  border-radius: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 11px 48px;
}
@media (max-width: 1023.98px) {
  .button.button-primary, .button.button-secondary {
    font-size: 0.8125rem;
  }
}
.button.button-primary .icon, .button.button-secondary .icon {
  height: 21px;
  width: 21px;
  margin-bottom: -4px;
  margin-top: -1px;
}
@media (max-width: 1023.98px) {
  .button.button-primary .icon, .button.button-secondary .icon {
    margin-bottom: -6px;
  }
}
.button.button-primary .icon.icon-before, .button.button-secondary .icon.icon-before {
  margin-left: -32px;
  margin-right: 20px;
}
.button.button-primary .icon.icon-after, .button.button-secondary .icon.icon-after {
  margin-right: -32px;
  margin-left: 20px;
}
.button.button-primary {
  background: #000;
  color: #fff;
}
.button.button-primary:hover {
  background-color: #f37d91;
  border-color: #f37d91;
  box-shadow: 0 0 0 1px #f37d91;
}
.button.button-primary:focus, .button.button-primary:active {
  background-color: #eb1f41;
  border-color: #eb1f41;
  box-shadow: 0 0 0 1px #eb1f41;
}
.button.button-primary.white {
  background: #fff;
  color: #ef4e69;
}
.button.button-primary.white:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white:focus, .button.button-primary.white:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-primary.white-purple {
  background: #fff;
  color: #7c51a1;
  border-color: #7c51a1;
}
.button.button-primary.white-purple:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white-purple:focus, .button.button-primary.white-purple:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-primary.white-red {
  background: #fff;
  color: #ef4e69;
  border-color: #ef4e69;
}
.button.button-primary.white-red:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white-red:focus, .button.button-primary.white-red:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-primary.white-green {
  background: #fff;
  color: #38ba9b;
  border-color: #38ba9b;
}
.button.button-primary.white-green:hover {
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 1px white;
}
.button.button-primary.white-green:focus, .button.button-primary.white-green:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
}
.button.button-secondary {
  color: #ef4e69;
}
.button.button-secondary:hover {
  box-shadow: 0 0 0 1px #ef4e69;
}
.button.button-secondary:focus, .button.button-secondary:active {
  color: #eb1f41;
  box-shadow: 0 0 0 1px #eb1f41;
  border-color: #eb1f41;
}
.button.button-secondary.white {
  color: #fff;
  border: 2px solid #fff;
}
.button.button-secondary.white:hover {
  box-shadow: 0 0 0 1px #fff;
}
.button.button-secondary.white:focus, .button.button-secondary.white:active {
  color: #e6e6e6;
  box-shadow: 0 0 0 1px #e6e6e6;
  border-color: #e6e6e6;
}
.button.button-text {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ef4e69;
  text-transform: uppercase;
  position: relative;
}
.button.button-text:after {
  content: " ";
  display: block;
  height: 1px;
  width: 100%;
  background-color: #ef4e69;
  position: absolute;
  bottom: 0;
  transform: scaleX(0);
  transition: 0.2s ease all;
}
.button.button-text:hover:after, .button.button-text:active:after, .button.button-text:focus:after {
  transform: scaleX(1);
}
.button.button-text .icon {
  height: 15px;
  width: 15px;
  margin-bottom: -3px;
  margin-top: -2 px;
}
.button.button-text .icon.icon-small {
  width: 10px;
  height: 10px;
  margin-bottom: 0;
  margin-top: 1px;
}
.button.button-text .icon.icon-before {
  margin-left: 0;
  margin-right: 5px;
}
.button.button-text .icon.icon-after {
  margin-right: 0;
  margin-left: 5px;
}
.button.button-spec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  font-weight: bold;
  font-size: 1rem;
  line-height: 125%;
  color: #fff;
  text-transform: uppercase;
  padding-top: 5px;
  padding-left: 18px;
  padding-right: 18px;
  background: #000;
}
@media (min-width: 1024px) {
  .button.button-spec {
    min-width: 190px;
  }
}
.button.button-spec.purple:hover {
  background: #956fb6;
}
.button.button-spec.purple:active, .button.button-spec.purple:focus {
  background: #62407f;
}
.button.button-spec.red:hover {
  background: #f37d91;
}
.button.button-spec.red:active, .button.button-spec.red:focus {
  background: #eb1f41;
}
.button.button-spec.green:hover {
  background: #58cdb1;
}
.button.button-spec.green:active, .button.button-spec.green:focus {
  background: #2c937a;
}
.button.button-spec.blue-light:hover {
  background: #98bde2;
}
.button.button-spec.blue-light:active, .button.button-spec.blue-light:focus {
  background: #4889cc;
}
.button.button-spec .icon-arrow-right-button-white {
  width: 10px;
  height: 10px;
  margin-top: -3px;
  margin-left: 12px;
  transition: 0.3s ease all;
}
.button.button-spec .icon-arrow-right-button-white.opened {
  transform: rotate(90deg);
}

.selectlike-container {
  position: relative;
}

.selectlike-button {
  text-align: left;
  width: 350px;
  max-width: 100%;
  border-bottom: 1px solid #ef4e69;
  text-transform: uppercase;
  color: #ef4e69;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  padding: 9px 40px 9px 17px;
  position: relative;
}
.selectlike-button.white {
  color: #fff;
  border-bottom-color: #fff;
}
.selectlike-button.type-b {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 125%;
  text-transform: none;
}
.selectlike-button:after {
  content: " ";
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-red.webp");
  transition: 0.3s ease all;
}
.selectlike-button.active:after {
  transform: translateY(-50%) scaleY(-1);
}
.selectlike-button.white:after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/arrow-down-more-white.webp");
}

.selectlike-content-type-a {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  background: #fff;
  text-align: left;
  transform-origin: 50% 0;
  transform: scaleY(0);
  pointer-events: none;
  transition: 0.4s ease all;
  opacity: 0;
}
.selectlike-content-type-a.wide {
  width: 200%;
  left: 50%;
  transform: translateX(-50%) scaleY(0);
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.wide {
    width: 100%;
    left: 0;
    transform: scaleY(0);
  }
}
.selectlike-content-type-a.wide.opened {
  transform: translateX(-50%) scaleY(1);
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.wide.opened {
    transform: scaleY(1);
  }
}
.selectlike-content-type-a.xxl {
  width: 300%;
  left: -50%;
  transform: translateX(-50%) scaleY(0);
}
.selectlike-content-type-a.xxl .thematics {
  width: unset !important;
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.xxl {
    width: 100%;
    left: 0;
    transform: scaleY(0);
  }
}
.selectlike-content-type-a.xxl.opened {
  transform: translateX(-50%) scaleY(1);
}
@media (max-width: 1023.98px) {
  .selectlike-content-type-a.xxl.opened {
    transform: scaleY(1);
  }
}
.selectlike-content-type-a.opened {
  opacity: 1;
  transform: scaleY(1);
  pointer-events: all;
}
.selectlike-content-type-a .element {
  width: 100%;
  padding: 4px 18px;
  color: #ef4e69;
  font-size: 1rem;
  line-height: 150%;
  text-transform: uppercase;
  text-align: left;
}
.selectlike-content-type-a .element:nth-child(even) {
  background: rgba(0, 0, 0, 0.04);
}
.selectlike-content-type-a .element:nth-child(odd) {
  background: rgba(0, 0, 0, 0.02);
}

.clear-filter-button {
  display: flex;
  align-items: center;
  margin-top: 55px;
  text-transform: uppercase;
  padding: 14px 10px 10px;
  border-radius: 22px;
}
@media (min-width: 1024px) {
  .clear-filter-button {
    margin-left: auto;
    font-weight: normal;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #fff;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button {
    justify-content: center;
    min-width: 180px;
    margin: 30px auto 0;
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    line-height: 18px;
    letter-spacing: 1px;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.red {
    border: 2.5px solid #ef4e69;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.red {
    color: #ef4e69;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.green {
    border: 2.5px solid #38ba9b;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.green {
    color: #38ba9b;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.purple {
    border: 2.5px solid #7c51a1;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button.purple {
    color: #7c51a1;
  }
}
@media (min-width: 1024px) {
  .clear-filter-button .icon {
    width: 12px;
    height: 12px;
    margin-right: 12px;
  }
  .clear-filter-button .icon:not(.icon-close-white) {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .clear-filter-button .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  .clear-filter-button .icon.icon-close-white {
    display: none;
  }
}

ul li .content {
  transition: transform 0.5s linear;
}

ul li:not(.slick-current) .content {
  transform: scale(0.9);
}

ul li .content {
  transition: transform 0.3s linear;
  transition-delay: 0.5s;
}

ul li:not(.slick-current) .content {
  transform: scale(0.9);
  transition-delay: 0s;
}

.slick-slide {
  outline: none;
}

.slick-prev,
.slick-next {
  width: 42px;
  height: 42px;
  z-index: 105;
  background-color: red;
}
.slick-prev:before,
.slick-next:before {
  content: "";
}
@media (max-width: 799.98px) {
  .slick-prev,
.slick-next {
    display: none !important;
  }
}

.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots {
  bottom: 25px;
  transform: translateX(-50%);
  width: auto;
  left: 50%;
}
@media (max-width: 799.98px) {
  .slick-dots {
    display: none !important;
    width: 100%;
  }
}
.slick-dots li {
  width: auto;
  height: auto;
  margin: 0 7.5px;
  line-height: 0;
}
.slick-dots li.slick-active button {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/cardot-active.svg");
}
@media (max-width: 799.98px) {
  .slick-dots li {
    margin: 0 2.5px;
  }
}
.slick-dots li button {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/cardot.svg");
  width: 28px;
  height: 28px;
  padding: 0;
}
.slick-dots li button:before {
  display: none;
}
@media (max-width: 799.98px) {
  .slick-dots li button {
    width: 20px;
    height: 20px;
  }
}

.custom-input input[type=text],
.custom-input input[type=email],
.custom-input input[type=password] {
  width: 100%;
  background: #efeff0;
  border-radius: 30px;
  height: 44px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 125%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #2d2b2c;
}
.custom-input input[type=text]::placeholder,
.custom-input input[type=email]::placeholder,
.custom-input input[type=password]::placeholder {
  color: #c4c4c4;
}

/* Basic styles */
.custom-checkbox {
  /* Checkbox */
  /* Radio */
  /* :checked */
  /* Transition */
}
.custom-checkbox input[type=checkbox],
.custom-checkbox input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 50px;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
  color: #fff;
}
.custom-checkbox label::before,
.custom-checkbox label::after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.custom-checkbox label::before {
  content: " ";
  border: 1px solid #fff;
}
.custom-checkbox input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check.svg");
  background-size: 60%;
  content: " ";
}
.custom-checkbox input[type=radio] + label::before {
  border-radius: 50%;
}
.custom-checkbox input[type=radio] + label::after {
  content: " ";
  top: calc(50% - 9px);
  left: 0;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
}
.custom-checkbox input[type=checkbox]:checked + label::before,
.custom-checkbox input[type=radio]:checked + label::before {
  border-color: #fff;
}
.custom-checkbox input[type=checkbox] + label::after,
.custom-checkbox input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.custom-checkbox input[type=checkbox]:checked + label::after,
.custom-checkbox input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-checkbox label::before,
.custom-checkbox label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.custom-checkbox.black label {
  color: #000;
}
.custom-checkbox.black label::before {
  border: 1px solid #000;
}
.custom-checkbox.black input[type=radio] + label::after {
  background: #000;
  border: 1px solid #000;
}
.custom-checkbox.black input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
}
.custom-checkbox.black input[type=checkbox]:checked + label::before,
.custom-checkbox.black input[type=radio]:checked + label::before {
  border-color: #000;
}

.custom-checkbox-c {
  /* Checkbox */
  /* Radio */
  /* :checked */
  /* Transition */
}
.custom-checkbox-c input[type=checkbox],
.custom-checkbox-c input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox-c label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 50px;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
  color: #2d2b2c;
}
.custom-checkbox-c label::before,
.custom-checkbox-c label::after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.custom-checkbox-c label::before {
  content: " ";
  border: 1px solid #2d2b2c;
}
.custom-checkbox-c input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
  content: " ";
}
.custom-checkbox-c input[type=radio] + label::before {
  border-radius: 50%;
}
.custom-checkbox-c input[type=radio] + label::after {
  content: " ";
  top: calc(50% - 9px);
  left: 0;
  width: 18px;
  height: 18px;
  background: #2d2b2c;
  border: 1px solid #2d2b2c;
  border-radius: 50%;
}
.custom-checkbox-c input[type=checkbox]:checked + label::before,
.custom-checkbox-c input[type=radio]:checked + label::before {
  border-color: #2d2b2c;
}
.custom-checkbox-c input[type=checkbox] + label::after,
.custom-checkbox-c input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.custom-checkbox-c input[type=checkbox]:checked + label::after,
.custom-checkbox-c input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-checkbox-c label::before,
.custom-checkbox-c label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.custom-checkbox-c.black label {
  color: #000;
}
.custom-checkbox-c.black label::before {
  border: 1px solid #000;
}
.custom-checkbox-c.black input[type=radio] + label::after {
  background: #000;
  border: 1px solid #000;
}
.custom-checkbox-c.black input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
}
.custom-checkbox-c.black input[type=checkbox]:checked + label::before,
.custom-checkbox-c.black input[type=radio]:checked + label::before {
  border-color: #000;
}

/* Basic styles */
.custom-checkbox-b {
  /* Checkbox */
  /* Radio */
  /* :checked */
  /* Transition */
}
.custom-checkbox-b input[type=checkbox],
.custom-checkbox-b input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox-b label {
  position: relative;
  display: inline-block;
  padding: 2px 0 0 30px;
  height: 1.5em;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 15px;
  letter-spacing: 0.03em;
  color: #000;
}
.custom-checkbox-b label::before,
.custom-checkbox-b label::after {
  position: absolute;
  top: calc(50% - 9px);
  left: 0;
  display: block;
  width: 18px;
  height: 18px;
}
.custom-checkbox-b label::before {
  border-radius: 50%;
  content: " ";
  border: 1px solid #ef4e69;
}
.custom-checkbox-b input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-red.svg");
  background-size: 60%;
  content: " ";
}
.custom-checkbox-b input[type=radio] + label::before {
  border-radius: 50%;
}
.custom-checkbox-b input[type=radio] + label::after {
  content: " ";
  top: calc(50% - 9px);
  left: 0;
  width: 18px;
  height: 18px;
  background: #ef4e69;
  border: 1px solid #ef4e69;
  border-radius: 50%;
}
.custom-checkbox-b input[type=checkbox]:checked + label::before,
.custom-checkbox-b input[type=radio]:checked + label::before {
  border-color: #ef4e69;
}
.custom-checkbox-b input[type=checkbox] + label::after,
.custom-checkbox-b input[type=radio] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
.custom-checkbox-b input[type=checkbox]:checked + label::after,
.custom-checkbox-b input[type=radio]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.custom-checkbox-b label::before,
.custom-checkbox-b label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.custom-checkbox-b.black label {
  color: #000;
}
.custom-checkbox-b.black label::before {
  border: 1px solid #000;
}
.custom-checkbox-b.black input[type=radio] + label::after {
  background: #000;
  border: 1px solid #000;
}
.custom-checkbox-b.black input[type=checkbox] + label::after {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/check-black.svg");
  background-size: 60%;
}
.custom-checkbox-b.black input[type=checkbox]:checked + label::before,
.custom-checkbox-b.black input[type=radio]:checked + label::before {
  border-color: #000;
}

/* toggle-like-checkbox */
.custom-checkbox-toggle {
  /* :checked */
}
.custom-checkbox-toggle input[type=checkbox],
.custom-checkbox-toggle input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.custom-checkbox-toggle label {
  position: relative;
  display: inline-block;
  padding: 2px 0 0 60px;
  height: 1.5em;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #2d2b2c;
}
.custom-checkbox-toggle label::before,
.custom-checkbox-toggle label::after {
  position: absolute;
  display: block;
}
.custom-checkbox-toggle label::before {
  left: 0;
  top: calc(50% - 11px);
  background: #efeff0;
  width: 48px;
  height: 24px;
  border-radius: 12px;
  content: " ";
}
.custom-checkbox-toggle label::after {
  top: calc(50% - 9px);
  left: 2px;
  background: #979797;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  content: " ";
  transition: 0.3s ease all;
}
.custom-checkbox-toggle input[type=checkbox]:checked + label::after,
.custom-checkbox-toggle input[type=radio]:checked + label::after {
  left: 26px;
  background: #38ba9b;
}

.filter-tag {
  margin: 5px;
  border-radius: 22px;
  font-size: 0.75rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  height: 28px;
  color: #fff;
  padding: 0 16px;
}
@media (max-width: 1023.98px) {
  .filter-tag {
    display: inline-block;
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.filter-tag.red {
  background: #ef4e69;
}
.filter-tag.blue-light {
  background: #70a3d7;
}
.filter-tag.purple {
  background: #7c51a1;
}
.filter-tag.green {
  background: #38ba9b;
}
.filter-tag.orange {
  background-color: #f79462;
}
.filter-tag.blue {
  background: #003375;
}
.filter-tag.clear {
  color: #ef4e69;
}
.filter-tag.clear .icon {
  width: 12px;
  height: 12px;
  margin-right: 12px;
  font-size: 0;
}
@media (max-width: 1023.98px) {
  .filter-tag.clear .icon {
    position: relative;
    top: -4px;
  }
}
.filter-tag .filter-off {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 12px;
  font-size: 0;
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/images/icons/x.svg");
}
@media (max-width: 1023.98px) {
  .filter-tag .filter-off {
    position: relative;
    top: -4px;
  }
}

.filter-clear {
  margin-left: auto;
}

.specicon {
  display: block;
  position: absolute;
  top: -55px;
  left: calc(50% - 70px);
  width: 110px;
  height: 110px;
  z-index: 7;
  opacity: 1;
}

.card.card--info,
.card.card--trip {
  padding: 5px;
  display: block;
  cursor: pointer;
}
@media (max-width: 1023.98px) {
  .card.card--info,
.card.card--trip {
    padding: 3px;
  }
}
.card.card--info .card__inner,
.card.card--trip .card__inner {
  display: flex;
  align-items: flex-end;
  height: 444px;
  position: relative;
  background-color: #000;
  overflow: hidden;
}
@media (max-width: 1279.98px) {
  .card.card--info .card__inner,
.card.card--trip .card__inner {
    height: calc(50vw - 20px);
  }
}
@media (min-width: 800px) {
  .card.card--info .card__inner:hover .card__content::before,
.card.card--trip .card__inner:hover .card__content::before {
    top: 0;
  }
  .card.card--info .card__inner:hover .card__text,
.card.card--trip .card__inner:hover .card__text {
    height: 100%;
    opacity: 1;
  }
}
.card.card--info .card__inner .card__bg,
.card.card--trip .card__inner .card__bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000 center/cover no-repeat;
  z-index: 1;
}
.card.card--info .card__inner .card__content,
.card.card--trip .card__inner .card__content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  z-index: 2;
}
.card.card--info .card__inner .card__content::before,
.card.card--trip .card__inner .card__content::before {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: 100%;
  transition: 0.35s ease;
  z-index: -1;
  content: "";
  background: linear-gradient(0deg, rgba(15, 22, 45, 0.8) 0%, rgba(15, 22, 45, 0.7) 80%, rgba(15, 22, 45, 0) 100%);
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__content::before,
.card.card--trip .card__inner .card__content::before {
    top: calc(100% - 95px);
  }
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__content::after,
.card.card--trip .card__inner .card__content::after {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    background: url("../../assets/images/icons/arrow-right-white-lite.webp") center/100% no-repeat;
    content: "";
  }
}
.card.card--info .card__inner .card__locale,
.card.card--trip .card__inner .card__locale {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.8);
}
@media (min-width: 1024px) {
  .card.card--info .card__inner .card__locale,
.card.card--trip .card__inner .card__locale {
    width: 56px;
    height: 56px;
  }
}
.card.card--info .card__inner .card__locale .icon,
.card.card--trip .card__inner .card__locale .icon {
  width: 21.5px;
  height: 21.5px;
}
@media (min-width: 1024px) {
  .card.card--info .card__inner .card__locale .icon,
.card.card--trip .card__inner .card__locale .icon {
    width: 30px;
    height: 30px;
  }
}
.card.card--info .card__inner .card__header,
.card.card--trip .card__inner .card__header {
  padding: 15px;
}
.card.card--info .card__inner .card__title,
.card.card--info .card__inner .card__subtitle,
.card.card--info .card__inner .card__text > *,
.card.card--trip .card__inner .card__title,
.card.card--trip .card__inner .card__subtitle,
.card.card--trip .card__inner .card__text > * {
  display: inline-block;
  padding: 5px;
  color: #fff;
}
.card.card--info .card__inner .card__title,
.card.card--trip .card__inner .card__title {
  letter-spacing: 0.01em;
  font-style: normal;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 150%;
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__title,
.card.card--trip .card__inner .card__title {
    font-size: 1.25rem;
    line-height: 120%;
  }
}
.card.card--info .card__inner .card__subtitle,
.card.card--trip .card__inner .card__subtitle {
  min-height: 18px;
  padding: 3px 10px 3px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0 10px 0 0;
}
@media (max-width: 1023.98px) {
  .card.card--info .card__inner .card__subtitle,
.card.card--trip .card__inner .card__subtitle {
    font-size: 0.625rem;
    line-height: 20px;
    position: relative;
    top: 11px;
  }
}
@media (max-width: 599.98px) {
  .card.card--info .card__inner .card__subtitle,
.card.card--trip .card__inner .card__subtitle {
    display: none;
  }
}
.card.card--info .card__inner .card__text,
.card.card--trip .card__inner .card__text {
  display: flex;
  align-items: center;
  height: 0;
  padding: 0 15px;
  transition: 0.5s ease;
  opacity: 0;
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  letter-spacing: 0.01em;
}
@media (max-width: 1023.98px) {
  .card.card--info .card__inner .card__text,
.card.card--trip .card__inner .card__text {
    font-size: 0.8125rem;
  }
}
@media (max-width: 599.98px) {
  .card.card--info .card__inner .card__text,
.card.card--trip .card__inner .card__text {
    display: none;
  }
}
.card.card--info .card__inner app-destination-icon,
.card.card--trip .card__inner app-destination-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 120px;
  transition: 0.35s ease;
  z-index: 3;
}

.card.card--info .card__inner .card__header {
  padding: 40px 15px 15px;
}
@media (min-width: 1024px) {
  .card.card--info .card__inner .card__header {
    padding-top: 56px;
  }
}
@media (max-width: 799.98px) {
  .card.card--info .card__inner .card__header {
    padding: 40px 35px 15px 15px;
  }
}

.corporate-progress-bar {
  background-color: #4582ec !important;
}

.corporate-button-loader:after,
.corporate-img-loader:before,
.corporate-fullscreen-loader:before {
  border-radius: 50%;
  border-top: 2px solid #4582ec;
  border-right: 2px solid transparent;
  animation: spinner 0.7s linear infinite;
}

.corporate-button-loader:after {
  content: "";
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-left: 10px;
}

.corporate-img-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 60px;
  width: 60px;
  margin-top: calc(-60px / 2);
  margin-left: calc(-60px / 2);
}

.corporate-fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10002;
}
.corporate-fullscreen-loader:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 120px;
  width: 120px;
  margin-top: calc(-120px / 2);
  margin-left: calc(-120px / 2);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}