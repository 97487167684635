// Sizing stuff
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Center-block
@mixin center-block {
  margin-left: auto;
  margin-right: auto;
}

// Vertical center
@mixin center-vertically {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Placeholders
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

// Display block icon
@mixin icon($name) {
  display: inline-block;
  min-width: 5px;
  min-height: 5px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url($images-path + $name);
}

// Background image
@mixin img($file) {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url($images-path + $file);
}

// IE10 IE11 only
@mixin ieonly() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin ffonly() {
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin noResults() {
  .no-results {
    width: 1102px;
    max-width: calc(100% - 30px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    text-align: left;

    @include media-breakpoint-down(md) {
      font-size: toRem(22);
      margin-bottom: 40px;
    }
  }
}
