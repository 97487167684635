/* Focusing with a keyboard */
*:focus-visible,
*:focus-visible + label {
  outline: 8px solid #ffa200 !important;
}

/* Focusing with a mouse, touch, or stylus */
*:focus:not(:focus-visible) {
  outline: none !important;
}

input:focus:hover {
  outline: 0 !important;
}
